.press{
    width: 100vw;
    width: 100dvw;
    display: flex;
    flex-direction: column;
}

.box-2 blockquote {
    display: flex;
    flex-flow: column nowrap;
    padding: 2.5rem;
    font-size: 1.3rem;
}

/* --------- MEDIA CHANGES --------- */

@media (max-width: 1100px) {

    .box-2 blockquote {
        padding: 0rem;
    }

    cite {
        padding-block: 1rem;
    }
}