
header {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-flow: row wrap;
    font-size: 3.6rem;
    font-weight: 500;
    margin: 0 auto;
}

header p {
    padding-inline: 2rem;
}



.workview-image-1 {
    width: 100%;
    height: 100vh;
    background-image: url('../assets/SanDiego.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-block: 2rem;
    margin-block: 2rem;
}

.recent-work {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-flow: column nowrap;
    padding-block: 2rem;
    margin: 0 auto;
}

.recent-work h2 {
    padding-inline: 2rem;
    font-size: 2.5rem;
}

.recent-work p {
    padding-inline: 2rem;
    width: 60%;
}

@media (max-width: 1100px) {
    header {
        font-size: 2rem;
    }

    header p {
        padding-inline: 1rem;
    }
    
    .workview-image-1 {
        height: 50vh;
        height: 50dvh;
        margin-block: 2rem;
    }
    
    .recent-work {
        width: 100%;
        gap: 0.25rem;
        padding-inline: 0rem;
        padding-block: 0rem;
    }

    .recent-work h2 {
        font-size: 2rem;
        padding-inline: 1rem;
    }

    .recent-work p {
        width: 80%;
        padding-inline: 1rem;
    }
    
}
