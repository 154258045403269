.contact-section {
    height: 500px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    padding-block:2rem;
    margin-block: 2rem;
    color: #fff;
    background-size: cover;
    background-position: center;
    background-color: white;
}

.children-container {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: left;
    align-items: flex-start;
    margin: 0 auto;
    gap: 1rem;
}

.contact-section h2,
.contact-section-dark h2,
.contact-section-with-background h2 {
    font-size: 2.6rem;
    padding-inline: 2rem;

}

.contact-section p,
.contact-section-dark p,
.contact-section-with-background p {
    font-size: 1.1rem;
    width: 50%;
    padding-inline: 2rem;


}


.contact-section.dark {
    background: #28282a;
}

.contact-section.about {
    background-image: url('../assets/contact-background-about.jpeg');
}

.contact-section.press {
    background-image: url('../assets/contact-background-press.jpg');
}

.contact-section.certifications {
    background-image: url('../assets/contact-background-certifications.jpg');
}

.contact-section-dark {
    background-color: #2C2C2C;
    color: white;
}

.contact-section-with-background {
    background-color: transparent;
    border-color: transparent;
}

@media (max-width: 1100px) {
    .contact-section {
        height: auto;
    }
    
    .children-container {
        max-width: 1400px;
        display: flex;
        flex-flow: column nowrap;
        margin: 0 auto;
        gap: 0rem;
    }
    
 
    .contact-section h2,
    .contact-section-dark h2,
    .contact-section-with-background h2 {
        font-size: 2rem;
        padding-bottom: 0rem;
        padding-inline: 1rem;
    }
    
    .contact-section p,
    .contact-section-dark p,
    .contact-section-with-background p {
        font-size: 1.1rem;
        width: 70%;
        padding-bottom: 1rem;
        padding-inline: 1rem;
    
    }
}