.media-menu {
    font-size: 1rem;
    position: fixed;
    top: 0;
    left: 0; 
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
    background: white;
    z-index: 10;
}

.menu-nav-container {
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    position: relative;
    font-size: .75rem;
}

.close-button {
    display: inline;
    font-size: 2rem;
    position: absolute; 
    right: .5rem;
    top: 55%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    background: transparent;
    border: none;
}

.menu-list {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: 2rem;
    font-weight: 400;
    padding-block: 2rem;
    list-style-type: none;
    margin: 0;
}
