.App {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.container-1400 {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.dual-container-1400 {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  padding-block: 5rem;
}


.box-1 {
  width: 40%;
  display: flex;
  flex-flow: column nowrap;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.75;
  letter-spacing: 1.5px;
  padding-block: 4rem;
  padding-inline: 2rem;
}

.box-2 {
  width: 60%;
  line-height: 1.5;
  padding-block: 3rem;
  padding-inline: 2rem;
}

/* --------- MEDIA CHANGES --------- */


@media (max-width: 600px) {

  .dual-container-1400 {
    display: flex;
    flex-flow: column nowrap;
    padding-block: 0rem;
    padding-inline: 1rem;
  }

  .box-1 {
      width: 100%;
      padding-block: 0;
      padding-inline: 0rem;
  }
  
  .box-2 {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      padding-block: 1rem;
      padding-bottom: 4rem;
      padding-inline: 0rem;
  }
}

