.project-gallery {
    max-width: calc(100% - 4rem);
    margin: 0 auto;
    display: flex;
}

.project-card-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-block: 2rem;
    padding-inline: 2rem;
    margin: 0 auto;
    margin-block: 2rem;
    gap: 4rem;
}

.project-card {
    flex: 1 1 calc(50% - 2rem);
    display: flex;
    flex-direction: column; 
    align-items: center;
    position: relative;
}

.image-container {
    position: relative;
    width: 100%;
}

.project-image {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    display: block;
    border: 1px solid black;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.project-card:hover .project-image {
    opacity: 0.7;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding-block: 10px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 100%;
}

.project-gallery-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding-block: 10px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 100%;
}

.project-card:hover .overlay {
    opacity: 1;
}

.project-card:hover .project-gallery-overlay {
    opacity: 1;
}

.overlay h5 {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
}

@media (max-width: 1100px) {

    .project-gallery {
        max-width: calc(100% - 2rem);
    }

    .project-card-container {
        flex-flow: column nowrap;
        margin-block: 0rem;
        padding-inline: 0rem;
        gap: 1rem;
    }

    .project-card {
        flex: 1 1 auto;
    }

       .project-card:hover .overlay {
        opacity: 1; 
    }

    .project-card:hover .project-image {
        opacity: 1;
    }

    .overlay {
        transition: none;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.8);

    }

    .project-gallery-overlay {
        opacity: 1;
        transition: none;
        top: 0; 
        left: 0; 
        transform: none;
        width: 30%; 
        height: 30%; 
        clip-path: polygon(0 0, 100% 0, 0 100%);
        text-align: left;
        padding: .5rem;
    }


}
