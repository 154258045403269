.project-navbar {
    width: 100vw;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    overflow: hidden;
}

.spreader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%; 
}

@media (max-width: 600px) {
    .project-navbar {
        font-size: 1.5rem; 
        padding-block: 2rem; 
    }

    .spreader {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
}
