/* Base Styles */

.contact {
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.contact-box-1 {
    display: flex;
    flex-direction: column; 
    width: 50%;
    padding: 1rem;
}

.contact-box-1 h2 {
    font-size: 2.5rem;
    text-align: center;
}

.hr-container {
    width: 50%;
    padding: 1rem;
    margin: 0 auto;
}

.contact-box-1 address {
    text-align: center;
}

.contact-box-2 {
    width: 50%;
    padding: 1rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.full-name-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .75rem;
    flex-wrap: wrap;
}

.full-name-container label {
    flex: 1;
}

label {
    display: flex;
    flex-direction: column;
}

input, textarea {
    background-color: #f0f9ff;
}

.contact-button {
    width: fit-content;
    display: inline-flex;
    text-align: center;
    align-items: center;
    padding-block: 1.25rem;
    padding-inline: 2.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    border: 1px solid black;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-color: black;
    color: white;
    transition: filter, scale .1s ease;
}

/* Media Query Styles */

@media (max-width: 600px) {
    .contact-box-1 {
        width: 100%;
        padding-inline: 0;
        text-align: center;
    }

    .hr-container {
        width: 70%;
    }

    .contact-box-2 {
        width: 100%;
        padding: 0;
    }

    .contact-button {
        margin-bottom: 2rem;
    }
}
