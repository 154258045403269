.navbar {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 2rem;
    margin: 0 auto;
    position: relative;
}

.main-title {
    font-size: 1.8rem;
    padding-inline: 2rem;
}

.navbar-list {
    display: flex;
    gap: 1.5rem;
    padding-inline: 2rem;
}

.navbar-list .active {
    border-bottom: 1px solid black;
}

.menu-icon {
    display: none;
}

/* --------- MEDIA CHANGES --------- */

@media (max-width: 1100px) {

    .navbar {
        padding-block: 1rem;
    }

    .main-title {
        font-size: 1.5rem;
        padding-inline: 1rem;
    }
    
    .navbar-list {
        display: none;
    }
    
    .menu-icon {
        display: inline;
        font-size: 2rem;
        position: absolute;
        right: 1rem;
        top: 55%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
    }
}
