/* Base button styles */
.button {
    margin-inline: 2rem;
    width: fit-content;
    display: inline-flex;
    text-align: center;
    align-items: center;
    padding-block: 1.25rem;
    padding-inline: 2.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    border: 1px solid;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-color: white;
    color: black;
    border-color: black;
    transition: opacity .3s ease;
}

/* Dark variant */
.button-dark {
    margin-inline: 2rem;
    width: fit-content;
    display: inline-flex;
    text-align: center;
    align-items: center;
    padding-block: 1.25rem;
    padding-inline: 2.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    border: 1px solid;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-color: black;
    color: white;
    border-color: black;
    transition: filter, scale .1s ease;
}

.button:hover {
    opacity: 0.5;
}

.button-dark:hover {
    filter: brightness(125%);
    scale: 102%;
}

/* Background image handling */
.has-background-image {
    background-color: transparent;
    border-color: transparent;
}

@media (max-width:1100px) {
    .button {
        margin-inline: 1rem;
    }

    .button-dark {
        margin-inline: 1rem;
    }
}



