.certifications-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #2C2C2C;
}

.certifications-container {
    width: 100%;
    max-width: calc(100% - 4rem);
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    gap: 2rem;
    justify-content: center;
    padding-block: 2rem;
    margin-block: 2rem;
}

.certification-image {
    background-size: cover;
    background-position: center;
    height: 350px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.certification-image.programming-certification {
    background-image: url('../assets/certificate-html-css.png');
}

.certification-image.html-css-certification {
    background-image: url('../assets/certificate-programming.png');
}

.certification-image.javascript-certification {
    background-image: url('../assets/certificate-javascript.png'); 
}

.certification-image.react-certification {
    background-image: url('../assets/certificate-react.png');
} 


@media (max-width: 1100px) {
    .certifications-view {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }
    
    .certifications-container {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: column nowrap;
        gap: 1.5rem;
        justify-content: center;
        align-items: center;
        padding-block: 2rem;
        margin-block: 2rem;
    }
    
    .certification-image {
        height: 45vh;
        width: 90vw;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
}